/** @format */

@font-face {
  font-family: Evil Empire;
  src: url(./evil_empire/EvilEmpire.otf);
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Montserrat:wght@200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,400;0,500;0,700;0,900;1,500&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                               loader
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
.loader {
  background: linear-gradient(180deg, #aef2ff 0%, #ffffff 100%);
  z-index: 1000 !important;
  position: absolute;
  top: 0px;
  // height: 120vh !important;
}

.credit {
  font-size: 16px;
  color: rgba(191, 191, 191, 0.36);
  font-family: Arial Narrow, sans-serif;
  position: fixed;
  right: 2%;
  top: 17%;
  text-align: center;
  display: none;
}

.credit a {
  text-decoration: none;
  display: block;
  color: rgba(191, 191, 191, 0.36);
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}

.credit a:hover {
  color: #283e4a;
}

.face .container {
  margin: auto;
  position: relative;
}

.face {
  // margin-top: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.loading {
  width: 130px;
  display: block;
  height: 2px;
  margin: 28px auto;
  border-radius: 2px;
  background-color: #cfcfcf;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.loading:before {
  content: "";
  height: 3px;
  width: 68px;
  position: absolute;
  -webkit-transform: translate(-34px, 0);
  -ms-transform: translate(-34px, 0);
  transform: translate(-34px, 0);
  background-color: #0073b1;
  border-radius: 2px;
  -webkit-animation: initial-loading 1.5s infinite ease;
  animation: animation 1.4s infinite ease;
}

@-webkit-keyframes animation {
  0% {
    left: 0;
  }

  50% {
    left: 100%;
  }

  100% {
    left: 0;
  }
}

@keyframes animation {
  0% {
    left: 0;
  }

  50% {
    left: 100%;
  }

  100% {
    left: 0;
  }
}

.face .container>img {
  // background: #0274b3;
  // -webkit-box-shadow: 0px 7px 20px 2px rgba(167, 167, 167, 0.6);
  // box-shadow: 0px 7px 20px 2px rgba(167, 167, 167, 0.6);
  border-radius: 2px;
  animation: bounce 1.4s ease infinite;
  -webkit-animation: bounce 1.4s ease infinite;
  -moz-animation: bounce 1.4s ease infinite;
  -ms-animation: bounce 1.4s ease infinite;
  -o-animation: bounce 1.4s ease infinite;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0);
    filter: blur(0);
  }

  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-filter: blur(1.4);
    filter: blur(1.4);
    // -webkit-box-shadow: 0px 0.3px 5px 0.5px rgb(167, 167, 167);
    // box-shadow: 0px 0.3px 5px 0.5px rgb(167, 167, 167);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0);
    filter: blur(0);
  }

  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-filter: blur(1.4);
    filter: blur(1.4);
    // -webkit-box-shadow: 0px 0.3px 5px 0.5px rgb(167, 167, 167);
    // box-shadow: 0px 0.3px 5px 0.5px rgb(167, 167, 167);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.tm{animation: blinks 2s linear 0s infinite normal forwards;

}

@keyframes blinks {
	0%,
	50%,
	100% {
		opacity: 1;
	}

	25%,
	75% {
		opacity: 0;
	}
}
.loading-h1 {
  font-family: "Evil Empire";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 170%;
  text-align: center;
  letter-spacing: 0.005em;
  text-transform: uppercase;
  color: #2c3a4b;
}

.caption {
  margin-top: 80px;
  text-align: center;
}

.caption p {
  font-family: "Inter";
  color: #a5abb3;
  font-size: 16px;
  font-weight: 400;
  margin: 0 0 8px;
  font-weight: normal;
  // text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
}

.caption ul {
  margin: 0;
  padding: 0;
}

.caption li {
  list-style: none;
  display: inline-block;
  letter-spacing: 3px;
  font-size: 18px;
  color: #f3f3f3;
  margin-right: 5px;
  font-family: Arial Narrow, sans-serif;
  animation: on-run 2.6s ease infinite;
  -webkit-animation: on-run 2.6s ease infinite;
  -moz-animation: on-run 2.6s ease infinite;
  -ms-animation: on-run 2.6s ease infinite;
  -o-animation: on-run 2.6s ease infinite;
}

.caption li:nth-child(1) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.caption li:nth-child(2) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin-right: 10px;
}

.caption li:nth-child(3) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.caption li:nth-child(4) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.caption li:nth-child(5) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  margin-right: 10px;
}

.caption li:nth-child(6) {
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.caption li:nth-child(7) {
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.caption li:nth-child(8) {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
}

@-webkit-keyframes on-run {
  0% {
    color: #f3f3f3;
  }

  25% {
    color: #0073b1;
  }

  50% {
    color: #0073b1;
  }

  75% {
    color: #f3f3f3;
  }

  100% {
    color: #f3f3f3;
  }
}

@keyframes on-run {
  0% {
    color: #f3f3f3;
  }

  25% {
    color: #0073b1;
  }

  50% {
    color: #0073b1;
  }

  75% {
    color: #f3f3f3;
  }

  100% {
    color: #f3f3f3;
  }
}

.caption li:last-child {
  margin-right: 0;
}

body::-webkit-scrollbar-track {
  background: #ffffff !important;
}

body::-webkit-scrollbar-thumb {
  background: #752bc0 !important;
  border-radius: 16px;
}

body::-webkit-scrollbar {
  width: 5px;
}

body {
  // background: url(https://aqua-tank.s3.us-west-1.amazonaws.com/assets/media/Background.svg) !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  scroll-behavior: smooth;
  user-select: none !important;
}

a {
  text-decoration: none !important;
}

.section-padding {
  padding: 100px 0px;
}

.safu {
  text-transform: uppercase;
  font-size: 48px;
  font-weight: 900;
  color: #4d1baa;
  user-select: none;
}

.safu2 {
  text-transform: uppercase;
  font-size: 38px;
  font-weight: 900;
  color: #4d1baa;
  user-select: none;
}

.card-bgs {
  background: #16dcff;

  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  // border: 1px dashed #f4f6f9;
  border-radius: 5px;
  width: 300px !important;
  height: 344px !important;
  padding: 20px !important;

  h5 {
    font-family: "Evil Empire" !important;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    letter-spacing: 0.008em;
    color: #2c3a4b;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    letter-spacing: 0.008em;
    color: #2c3a4b;
  }

  .img-box {
    display: block !important;
    justify-content: center;
    align-items: center;
    height: 190px;

    // background: url(./bg.svg) !important;
    overflow: hidden !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    margin-bottom: 10px;
    border-radius: 30px !important;

    img {
      display: block !important;
      aspect-ratio: square;
      object-fit: contain;
      float: clear !important;
    }
  }
}

.fade-bg {
  -webkit-mask-image: linear-gradient(90deg,
      transparent 0,
      #000 20%,
      #000 80%,
      transparent) !important;
  mask-image: linear-gradient(90deg,
      transparent 0,
      #000 20%,
      #000 80%,
      transparent) !important;
}

/*********************************************NAVBAR*******************************************/
.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none !important;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}

.navbar .navbar-toggler-icon:after {
  top: 8px;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.nav-btn {
  width: 190px;
  height: 45px;
  background: linear-gradient(90deg, #eb00ff 0.04%, #0064fb 100.04%);
  box-shadow: inset 0px -4px 4px rgba(98, 32, 150, 0.25),
    inset 0px 4px 4px rgba(98, 32, 150, 0.25);
  border: none;
  outline: none;
  border-radius: 20px 0px 20px 0px;
  color: white;
}

.navbar {
  background: #49e4fe;
  position: relative !important;
  z-index: 2;
}

.nav-link {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 170%;
  text-align: center;
  letter-spacing: 0.005em;
  // text-transform: uppercase;
  color: #2c3a4b !important;
}

/*********************************************HERO-SECTION*******************************************/
.font-head {
  font-family: "Evil Empire";

}

.hero-bg {
  background: linear-gradient(180deg, rgba(0, 200, 223, 0) 0%, #49e4fe 86.21%),
    url(./media/heroimg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;

  h1 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 44px;
    line-height: 130%;

    text-transform: uppercase;
    color: #2c3a4b;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
    text-align: center;
    color: #394452;
  }
}

.hero-image {}

.fixed-icons {
  width: 54px;
  height: 381px;
  background: linear-gradient(180deg,
      rgba(206, 250, 255, 0.8) 0%,
      rgba(206, 250, 255, 0) 100%);
  border: 0.5px solid #ffffff;
  backdrop-filter: blur(50px);
  position: fixed;
  top: 25%;
  right: 10px;
  border-radius: 40px;
  z-index: 1000 !important;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    color: white !important;

    &:hover {
      color: black !important;
    }
  }
}

.fixed-icons2 {
  height: 34px;
  max-width: 415px;
  background: linear-gradient(180deg,
      rgba(206, 250, 255, 0.8) 0%,
      rgba(206, 250, 255, 0) 100%);
  border: 0.5px solid #ffffff;
  backdrop-filter: blur(50px);

  border-radius: 40px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  p {
    padding-top: 15px !important;
    font-size: 14px;
  }

  a {
    color: white !important;

    &:hover {
      color: black !important;
    }
  }
}

.create-btn {
  color: #000;
  border: 0px !important;
  font-family: "Inter" !important;

  padding: 12;
  margin-bottom: 24;
  border-radius: 6;
  background: linear-gradient(180deg,
      rgba(206, 250, 255) 0%,
      rgba(206, 250, 255) 100%);

  &:hover {
    background-color: rgba(28, 57, 95, 1);
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////

.sec2-bg {
  position: relative !important;
  z-index: 2;
  background: #49e4fe url(https://aqua-tank.s3.us-west-1.amazonaws.com/assets/media/sec2-bg.svg) !important;
  background: #49e4fe;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;

  h1 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 76px;
    line-height: 170%;
    // text-align: center;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #ffdd15;

    span {
      color: black;
    }
  }

  p {
    font-family: "Inter";
    font-style: normal;
    // width: 570px;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #394452;
  }

  h3 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.05em;
    color: #2c3a4b;
  }
}

.sec2-bg2 {
  position: relative !important;
  z-index: 2;
  background: #49e4fe;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;

  h1 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 76px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #d709ff;

    span {
      color: black;
    }
  }

  p {
    font-family: "Inter";
    font-style: normal;
    // width: 570px;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #394452;
  }

  h3 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    // line-height: 170%;
    text-align: center;
    letter-spacing: 0.05em;
    color: #2c3a4b;
  }
}

.sec2-bg3 {
  position: relative !important;
  z-index: 2;
  background: #49e4fe;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 40px;

  h1 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    // line-height: 170%;
    padding-left: 30px;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #000000;

    span {
      color: black;
    }
  }

  p {
    font-family: "Inter";
    font-style: normal;
    // width: 570px;
    text-align: justify;
    font-weight: 400;
    font-size: 16px;

    letter-spacing: 0.005em;
    color: #394452;
  }

  h3 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.05em;
    color: #2c3a4b;
  }
}

.video-bg {
  position: relative !important;
  z-index: 2;
  background: url(./media/video.png);
  background-position: center;
  background-size: cover;
  max-width: 400px;
  height: 300px;
  text-align: center;

  // overflow: hidden !important;
  // background-blend-mode: screen;
  // mix-blend-mode: screen;
  h2 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 39px;
    color: #545d69;
    padding: 8px 0px;
  }
}

.card-bg {
  background: url(./media/cardbg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 287px;
  height: 334px;

  h5 {
    font-family: "Evil Empire" !important;

    margin-top: 8px;
  }
}

/////////////////////////////////////////////////////////////////////////////////////////////////////
.sec3-hero-bg {
  position: relative !important;
  z-index: 2;
  background: #49e4fe;
  background: url(./media/Seanatures.svg) #49e4fe;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 76px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: black;
  }

  span {
    color: #d709ff;
  }
}

.sec3-bg {
  position: relative !important;
  z-index: 2;
  background: url(./media/Untitled-1.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 1092px;
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 50px;

  // mix-blend-mode: screen;
  h2 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 54px;
    line-height: 90%;
    color: #394452;

    text-shadow: 0px 4px 8px rgba(0, 183, 233, 0.5);
  }

  .sec3-span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    color: #8f29f5;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////
.vd-sec {
  position: relative !important;
  clip-path: polygon(50% 0%, 100% 5%, 100% 100%, 75% 100%, 25% 100%, 0 100%, 0 6%);

  z-index: 2;
  background: #49e4fe;

  h1 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 76px;

    text-align: center;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: black;
  }
}

.vd-card {
  background: linear-gradient(180deg,
      rgba(206, 250, 255, 0.8) 0%,
      rgba(206, 250, 255, 0) 100%);
  border: 0.5px solid #ffffff;
  backdrop-filter: blur(50px);

  border-radius: 20px;

  iframe {
    border-radius: 15px !important;
  }
}

.tokenomics-bg {
  position: relative !important;
  z-index: 2;
  background: url(./media/Vector.svg) #49e4fe;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sec4-bg {
  background: url(./media/Rectangle14.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 1000px;
  height: 1100px;
  text-align: center;
  padding: 55px;
  margin: auto;

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.008em;
    color: #2c3a4b;
  }

  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    text-align: center;

    color: #545d69;
  }

  h1 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 55px;
    text-align: center;
    color: #2c3a4b;
  }
}

.sec4-img {
  position: relative;
  width: 250px;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////
.roadmap-bg {
  background: url(./media/Group.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #00cde4 !important;

  h1 {
    position: relative !important;
    z-index: 2;
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 76px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.008em;
    color: #2c3a4b;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    position: relative !important;
    z-index: 2;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #394452;
  }
}

.roadmap-bullets {
  position: relative !important;
  z-index: 2;

  h2 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #2c3a4b;
    margin-bottom: 20px;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 90%;
    letter-spacing: 0.008em;
    color: #394452;
    text-align: left;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
.team-bg {
  // background: url(https://aqua-tank.s3.us-west-1.amazonaws.com/assets/media/bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 280px;
  height: 330px;

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    text-align: center;
    color: #2c3a4b;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.008em;
    color: #394452;
  }
}

.team-hero-bg {
  background: #49e4fe;

  h1 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 76px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.008em;
    color: #2c3a4b;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #394452;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////
.partners {
  h3 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.008em;
    color: #2c3a4b;
  }
}

.partner-heading {
  position: relative !important;
  z-index: 2;

  h1 {
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 76px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.008em;
    color: #2c3a4b;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #394452;
  }
}

.partners {
  position: relative !important;
  z-index: 2;
  background: linear-gradient(180deg, #6beaff 0%, #ffffff 100%);
}

.partners-logo {
  display: block !important;
  justify-content: center;
  align-items: center;
  // height: 190px !important;
  // background: url(./bg.svg) !important;
  overflow: hidden !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  margin-bottom: 10px;
  border-radius: 30px !important;

  img {
    display: block !important;
  }
}

// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                               Timer
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
.timer span {
  // display: inline-block;
  padding-left: 15px;

  text-align: center;
  color: #000 !important;
}

.timer {
  float: none;
  margin: 0 auto;
}

.timer small {
  font-weight: 400 !important;
  font-family: "Inter";

  font-size: 10px !important;
}

.timer strong {
  font-size: 24px !important;
  font-family: "Inter";

}

.titles {
  background: linear-gradient(180deg,
      rgba(206, 250, 255, 0.8) 0%,
      rgba(206, 250, 255, 0) 100%);
  height: 60px;
  border: 0.5px solid #ffffff;
  backdrop-filter: blur(50px);
  border-radius: 40px;
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;

  section.main {
    font-weight: 500 !important;
    display: block;
  }

  h3 {
    font-size: 14px;
    font-family: "Inter" !important;
    color: red;
  }
}

.rond {
  border-radius: 50% !important;
  // width: 200px;
  // height: 200px;
}

.partner-bottom-row {
  position: relative !important;
  z-index: 2;

  h1 {
    padding: 50px 0px;
    font-family: "Evil Empire";
    font-style: normal;
    font-weight: 400;
    font-size: 76px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.008em;
    color: #2c3a4b;
  }
}

.heroim {
  position: relative !important;
  z-index: 2;
}

.footer {
  position: relative !important;
  z-index: 2;
  background: linear-gradient(180deg, #c3f6ff 0%, #ffffff 42.09%);

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    text-align: center;
    letter-spacing: 0.005em;
    color: #545d69;

    mix-blend-mode: normal;
  }

  li {
    margin: 0px 10px;

    a {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 170%;
      text-align: center;
      letter-spacing: 0.005em;
      text-decoration: none;
      color: #394452;
    }
  }
}

.footer-bottom {
  a {
    margin: 0px 20px;
    text-decoration: none;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #8f29f5;
  }
}

// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                               Faq
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
.faqs {
  position: relative;
}

.faq-row {
  margin: 20px auto !important;
  padding-left: 20px !important;
  border-radius: 15px;
  width: 100% !important;
  border-bottom: 0 !important;
  font-family: "Inter" !important;
}

.partner img {
  max-height: 100px !important;
}

.faqs h1 {
  font-family: "Evil Empire";
  font-style: normal;
  font-weight: 400;
  font-size: 76px;

  text-align: center;
  letter-spacing: 0.008em;
  color: #2c3a4b;
}

.faqs p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  text-align: center;
  letter-spacing: 0.005em;
  color: #394452;
}

/******************************************Responsiveness******************************************************/

@media only screen and (max-width: 600px) {
  .fixed-icons {
    display: none;
  }

  .timer {
    width: 100% !important;
  }

  .timer small {
    font-weight: 300 !important;
    font-size: 9px !important;
  }

  .timer strong {
    font-size: 29px !important;
  }

  .res-img {
    width: 100%;
  }

  .tokenomics-bg {
    width: 100% !important;
  }

  .team-bg {
    width: 100% !important;
    padding-bottom: 0px !important;
    margin-bottom: 10px;
    height: 390px;
  }

  .team-body {
    margin-top: 40px;
  }

  .table-responsive {
    width: 100%;
    height: 500px;
    overflow-x: hidden;
  }

  .partners {
    width: 100% !important;
  }

  .partners-logo {
    margin: 10px 0px;
  }

  .sec4-bg {
    padding: 0px 10px;
  }

  .res-partner {
    width: 100%;
  }

  .res-video-bg {
    width: 100%;

    img {
      width: 95%;
      margin-left: 17px !important;
      margin-top: 8px !important;
    }
  }

  .list-items {
    width: 70%;
    display: block;
  }

  .road-col {
    text-align: center !important;
    justify-content: center;
  }

  .road-map-img {
    margin-top: -70px;
  }

  .bullets-res {
    h2 {
      margin-left: 20px;
      font-size: 15px;
      margin-top: 5px;
    }

    p {
      margin-left: 20px;
    }
  }

  .res-text {
    font-size: 35px !important;
  }

  .card-bg {
    margin: auto;
  }

  .line-break {
    display: none;
  }
}